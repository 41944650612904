@font-face {
  font-family: 'Edwardian Script';
  src: url('assets/fonts/edwardian-script-itc-regular-webfont.woff2')
      format('woff2'),
    url('assets/fonts/edwardian-script-itc-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'centaur';
  src: url('assets/fonts/centaur-webfont.woff2') format('woff2'),
    url('assets/fonts/centaur-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  color: #4e3615;
  background-color: #e0c181;
  font-family: 'centaur', serif;
}

p {
  font-size: 18px;
}

.engagment-bg {
  background: #4e3615;
}

#menu-link:hover {
  text-decoration: underline;
}
